import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
//import store from './store'
import VueMobileDetection from "vue-mobile-detection";
import { analytics } from "@/firebase";

createApp(App)
    /*.use(store)*/
    .use(router)
    .use(VueMobileDetection)
    .mount("#app");

if (analytics) {
    console.log('Firebase Analytics initialized');
}