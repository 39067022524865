<template>
	<div class="mt-5 lato-bold text-center px-1 pt-5">Para utilizar esta app, escanee el c&oacute;digo QR desde un dispositivo m&oacute;vil</div>
	<div class="mt-3">
		<vue-qrcode :value="baseUrl" type="image/png" :width="400" :color="qrColor" />
	</div>
</template>

<script setup>
import VueQrcode from 'vue-qrcode'; // Asegúrate de que el importe es correcto para la librería que estás usando.
import { ref, onMounted } from 'vue';

// Variables reactivas
const baseUrl = ref('');
const qrColor = ref({
	dark: '#000000',
	light: '#ffffff',
});

// Configuración al montar el componente
onMounted(() => {
	console.log('Checking route and setting base URL');
	const location = window.location.href.replace(/\/(qr|onboarding|finished|unauthorized)/g, '');
	console.log({ location });
	baseUrl.value = location;
	console.log('baseUrl', baseUrl.value);
});
</script>
